<template>
  <!-- 路径【入库管理】>>>【入库单管理】（详情）>>>打印一次性容器 -->
  <div class="printWj">
    <print-header
      :barcode="transferOrderNo"
      :title="config.title"
    />
    <print-info :info="fileds.info" :form="printList.transferOrder" />
    <print-table
      :headers="fileds.tableHeaders"
      :list="tableData"
      class="print-table"
    />
    <el-row justify="end">
      <el-col :span="6">
        移库移位人:
      </el-col>
      <el-col :span="6">
        完成时间:
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Moment from '@/utils/moment';
import PrintHeader from '../../components/PrintHeader/PrintHeader.vue';
import PrintInfo from '../../components/PrintInfo/PrintInfo.vue';
import PrintTable from '../../components/PrintTable/PrintTable.vue';
import config from './config';
import fileds from './fileds';

export default {
  components: {
    PrintHeader,
    PrintInfo,
    PrintTable,
  },
  props: {
    printList: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      config,
      fileds,
      transferOrderNo: '',
      tableData: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const { transferOrder, goodsDetails } = this.printList;
      this.transferOrderNo = transferOrder.transferOrderNo;
      this.featchDate(goodsDetails);
    },
    // 通过发运单号获取发运单详情，基本信息页面
    featchDate(goodsDetails) {
      this.tableData = goodsDetails.map((item) => ({
        ...item,
        productDate: Moment.format(item.productDate),
        totalStockAmount: item.bigUnitCurrentStockAmount + item.bigUnit
        + item.smallUnitCurrentStockAmount + item.smallUnit,
      }));
    },
  },
};
</script>

<style lang="scss">
.printWj {
  page-break-after: always;
  padding: 8px;

  .print-table {
    font-size: 14px;
    font-family: Microsoft YaHei, Tahoma, Verdana, STHeiTi, simsun, sans-serif;
    margin: 24px 0;

    th {
      font-size: 12px;
      height: 30px;
      line-height: 30px;
      white-space: nowrap;
    }

    tbody td {
      height: auto;
      padding: 10px 0;
    }
  }
}

@media print {
  .print-btn {
    display: none;
  }
}
</style>
