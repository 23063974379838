// 打印单公共信息配置
export default {
  info: [
    [{
      label: '移库移位单号：',
      prop: 'transferOrderNo',
      span: 6,
    }],
  ],
  tableHeaders: [
    { label: '所属货主', prop: 'shipperName' },
    {
      label: '货主货品编码', prop: 'shipperGoodsCode', class: 'letter-spacing-1', width: '13.5%',
    },
    { label: '货品名称', prop: 'goodsName' },
    { label: '规格', prop: 'specification', width: '4%' },
    { label: '源库位', prop: 'sourceLocationCode' },
    { label: '库存总/数量', prop: 'totalStockAmount', width: '8%' },
    { label: '生产日期', prop: 'productDate', width: 80 },
    { label: '默认单位', prop: 'defaultUnit', width: '8%' },
    { label: '辅助单位', prop: 'auxiliaryUnit', width: '8%' },
    { label: '辅助数量', prop: 'currentAuxiliaryUnitAmount', width: '8%' },
    {
      label: '移库数量',
      width: '8%',
      children: [
        { label: '整单位', prop: 'bigUnitAmount' },
        { label: '零单位', prop: 'smallUnitAmount' },
      ],
    },
    { label: '移库辅助数量', prop: 'auxiliaryUnitAmount', width: 80 },
    { label: '目标库位', prop: 'targetLocationCode', width: 80 },
  ],
};
